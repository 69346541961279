<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex sm6>
        <div class='headline green--text text--darken-4 font-weight-medium'>
          Question
        </div>
      </v-flex>
      <v-flex sm12 xs12 lg12>
        <v-btn color='primary' v-show='allowAdd' @click='createDlg'>
          <v-icon class='iconText'>fa fa-plus</v-icon>
          New Question Group
        </v-btn>
      </v-flex>
      <v-flex sm3>
        <div class='filterStyle'>
          <v-autocomplete
            label='Question Group:'
            v-model='groupId'
            :items='groups'
            item-text='Name'
            item-value='Id'
          >
            <template slot='prepend'>
              <v-icon class='filterIcon grey--text text--darken-1'
                >fa fa-filter</v-icon
              >
            </template>
          </v-autocomplete>
        </div>
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <v-card>
          <v-card-text class='pa-0'>
            <QDisplay
              :loading='loading'
              :allow-delete='allowAdd'
              :allow-edit='allowEdit'
              :items='qItems'
            ></QDisplay>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import WebH from '../../api/web';
import QDisplay from '../../components/question/QuestionDisplay';
export default {
  components: {
    QDisplay
  },
  created() {
    this.allowAdd = this.$store.getters['UserStore/permissions'].includes(
      'qs.a'
    );
    this.allowEdit = this.$store.getters['UserStore/permissions'].includes(
      'qs.a'
    );
    this.api_getGroups();
    this.api_get(0);
  },
  data() {
    return {
      allowAdd: false,
      allowEdit: false,
      dlgShow: false,
      loading: false,
      items: [],
      groups: [],
      groupId: 0,
      dlgType: 0,
      selectedItem: null
    };
  },
  computed: {
    qItems() {
      if (this.groupId < 1) return this.items;
      return this.items.filter(x => x.QGId === this.groupId);
    }
  },
  methods: {
    closeDlg() {
      this.dlgShow = false;
    },
    viewDlg(item) {
      this.selectedItem = Object.assign({}, item);
      this.dlgType = 2;
      this.dlgShow = true;
    },
    editDlg(item) {
      this.selectedItem = Object.assign({}, item);
      this.dlgType = 1;
      this.dlgShow = true;
    },
    createDlg() {
      this.selectedItem = null;
      this.dlgType = 0;
      this.dlgShow = true;
    },
    addNewItem(item) {
      this.items.push(item);
      this.$notify({
        type: 'info',
        text: 'Successfully added new question'
      });
    },
    updateItem(item) {
      const index = this.items.findIndex(x => x.Id === item.Id);
      if (index > -1) {
        const tItem = this.items[index];
        tItem.Name = item.Name;
        tItem.Description = item.Description;
      }
    },
    async deleteDlg(item) {
      const res = await this.$notifyDlg.show(
        'Do you really want delete this question?',
        'Delete question',
        'warning',
        true
      );
      if (res) {
        this.api_delete(item);
      }
    },
    async api_getGroups() {
      this.loading = true;
      this.items = [];
      let url = '/QuestionGroups/';
      const res = await WebH.Get(url, 'question groups');
      if (res.Success) {
        this.groups.push({ Id: 0, Name: 'All' });
        for (let group of res.Data) {
          this.groups.push({ Id: group.Id, Name: group.Name });
        }
      }
      this.loading = false;
    },
    async api_get(id) {
      this.loading = true;
      this.items = [];
      let url = '/questions/' + id;
      const res = await WebH.Get(url, 'questions');
      if (res.Success) {
        this.items = res.Data;
      }
      this.loading = false;
    },
    async api_delete(data) {
      const res = await WebH.Delete('/Questions/' + data.Id);
      if (res.Success) {
        const index = this.items.findIndex(x => x.Id === data.Id);
        if (index > -1) {
          this.items.splice(index, 1);
        }
        this.$notify({
          type: 'info',
          text: 'Successfully deleted the question'
        });
      } else {
        await this.$notifyDlg.show(res.Data, 'Error', 'error');
      }
    }
  }
};
</script>

<style scoped></style>
